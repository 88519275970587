import React from 'react'
import SEO from '../components/seo.js'
import { Link, graphql } from 'gatsby'

export default ({ data }) => {
    const posts = data.allMarkdownRemark.edges

    return (
        <>
            <SEO title='Docs' />
            <ul className='mt-6 max-w-3xl mx-auto'>
                {posts.map(({ node }) => {
                    const title = node.frontmatter.title || node.fields.slug
                    return (
                        <Link key={node.fields.slug} to={node.fields.slug}>
                            <article
                                key={node.fields.slug}
                                className='my-2 border rounded px-4 py-4 hover:border-gray-700'
                            >
                                <header className='flex justify-between'>
                                    <h3 className='font-medium'>{title}</h3>
                                    <small>{node.frontmatter.date}</small>
                                </header>
                                <section className='text-sm'>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: node.frontmatter.description || node.excerpt,
                                        }}
                                    />
                                </section>
                            </article>
                        </Link>
                    )
                })}
            </ul>
        </>
    )
}

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { fields: { collection: { eq: "docs" } } }
        ) {
            edges {
                node {
                    excerpt
                    fields {
                        slug
                    }
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        title
                        description
                    }
                }
            }
        }
    }
`
